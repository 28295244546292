/* Modal Container CSS */
.modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    right: 0;
    width:100%; 
    max-width: 800px;  
    border-radius: 5px;
    border: 3px solid var(--secondary-accent);
    background-color: var(--main-bg-color-solid);
    padding:1em 2em;
    color: var(--primary-font-color);
    z-index: 3;
    transition: all 1s ease-out;

}
.modal-container:hover {
    border:3px solid var(--secondary-accent);
    box-shadow:1px 1px 30px var(--secondary-accent);

}
.modal-title {
    width: 100%;
    font-size: 1.2em;
    margin: 0;
}
.modal-close {
    float: right;
    font-weight: 800;
    cursor: pointer;
    
}
.modal-close:hover{
    color: var(--primary-accent);
}
@media screen and (max-width: 800px){
    .modal-container{
        min-height: 35%;
        border-radius: 0px;
        top: 0;
        left: 0;
        width:100%;
    }
}