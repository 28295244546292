/* alert mesages css */
#alertMessage {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width:100vw;
    border-bottom: 3px solid var(--secondary-accent);
    background-color: var(--main-bg-color-solid);
    padding:0px;
    font-size: 1em;
    z-index: 1000;
    transition: all 2s ease-out;
    text-align: center;
}
#alertTitle{
    height: 8vh;
    padding: 17px 15px;
    color: var(--primary-font-color);
    font-weight: 800;
}
#alertBody{
    padding: 15px;
    font-size: .9em;
    font-weight: 100;
    word-wrap: break-word;
}
.success {
    background: var(--success);
}
.caution {
    background: var(--caution);
    color: var(--inverse-font-color) !important;
}
.warning {
    background: var(--warning);
}
.disapear {
    opacity: 0;
}
@media screen and (max-width: 900px){
    #alertMessage{
        text-align: left;
    }
}
