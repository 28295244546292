footer{
  position: fixed;
    bottom:0;
    text-align: center;
    width: 100vw;
    margin-top:-29px;
    height: 30px;
    border-top: 1px solid var(--secondary-accent);
    font: normal small-caps 100 .9em/.9em 'Montserrat', sans-serif;
    z-index: 300;
  }
  footer p {
    padding-top: 6px;
  }
  footer span{
    font-size: .8em;
  }