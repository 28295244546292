#contact .hashLink {
    text-decoration: none;
}
#contact .scrollArrow{
    position: absolute;
    left: 50%;
    bottom: -5vh;
    transform: translate(-50%);
    margin: 60px auto;
    width:6em;
    height: 2.5rem;
    /* border: 2px solid var(--secondary-accent); */
    /* border-radius: 5px; */
    background-image:url('../images/downArrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: invert(100%);
    opacity: .5;
    transition: all .7s ease-in-out;
}
#contact #slide1 .scrollArrow{
    bottom: 0vh;
}
#contact input, #contact textarea {
    text-align: left;
    font-size: 1em;
    font-weight: 400;
    border: none;
    padding: 5px 5px;
    margin: 0 0 .5em 0;
    width: 100%;
    background-color: var(--third-bg-color);
    color: #fff;
    border-radius: 2px;
    transition:all 0.5s ease;
}
#contact input::placeholder, #contact textarea::placeholder {
    font-weight: 400;
    color: #fff;
    font-size: 1rem;
    font-weight: 100;
}

#contact input:focus, #contact textarea:focus {
    color: #000 !important;
    background-color: #fff
}
@media screen and (max-width: 800px) {
    #contact input::placeholder, #contact textarea::placeholder {
        font-size: 1.1em;
        font-weight: 600;
    }
}
