.imageContainer {
    position: relative;
    margin: 1em 1em 0 0;
    height: 144px;
    width: 144px;
    float: left;
    border-radius: 50%;
    background-image: radial-gradient(ellipse at 0% 0%,rgba(255, 255, 255, 1), white, #ffa500ee, #ffa500, var(--secondary-bg-color),var(--main-bg-color), var(--third-bg-color) );
    box-shadow: 2px 2px 20px var(--third-bg-color);
}
.profileImage {
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 90%;
    height: 90%;
    background-image: url("./../images/PaulRandallProfile.jpg"); 
    background-repeat: no-repeat;
    background-size: contain;
    border-radius:50%;
    opacity: .8;
}
#about p {
    margin: 30px 0 0 0;
    text-align: left;
}