#emailTable {
   width: 100%;
   text-align: left;
}
#emailTable thead tr th{
    padding: 8px 0;
}
#emailTable td {
    font-weight: 100;
    margin: 8px 0 0 0;
}
#emailTable .removeEmail{
  height: .8em;
  font-size: .8em;
  font-weight: 800;
    font-variant: small-caps;
    color: var(--warning);
}
#emailTable .removeEmail:hover {
    cursor: pointer;
    color: var(--warning);
}
@media(max-width: 535px){
    #viewEditEmailList {
        /* width:100vw; */
        padding: 0;
    }
    #emailTable , #emailTable thead, #emailTable td, #emailTable tr, #emailTable th { 
        display: block; 
        text-align: left; 
    } 
    #emailTable td { 
        padding-left: 10px; 
    }
    #emailTable thead { 
        display: none;
    }
  }
  