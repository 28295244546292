/* confirm messages css */
#confirmMessage {
    display: none;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:40vw;
    border: 3px solid var(--secondary-accent);
    background-color: var(--main-bg-color-solid);
    padding:0px;
    font-size: 1em;
    border-radius: 0px;
    z-index: 1000;
    transition: all 1s ease-out;
}
#confirmTitle{
    height: 10vh;
    padding: 22px 15px;
    color: var(--primary-font-color);
    font-weight: 800;
}
#confirmBody{
    padding: 15px;
    font-size: .9em;
    font-weight: 100;
    word-wrap: break-word;
}
#confirmMessage button {
    margin: 0px 10px 20px 15px;
}
.warning {
    background: var(--warning);
}
#confirmMessage:hover {
    box-shadow:1px 1px 30px var(--secondary-accent);
}
@media screen and (max-width: 900px){
    #confirmMessage{
        position: fixed;
        top:0;
        left:0;
        transform: translate(-1%, 0%);
        width:100vw;
        padding:0px;
        border: none;
        border: 3px solid var(--secondary-accent)
    }
}