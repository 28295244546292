#photo {
    position: relative;
}
#photo .inner-container{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
}
@media screen and (min-width: 800px){
  #gallery {
    margin: 100vh 0;
  }
}