/* Main CSS for pauldavidrandall.com */
/* Resets */
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  /*      Layout      */
  /*   Mobile First   */
  .container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100vw;
  }
  .slide{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    padding: 60px 1em;
  }
  section {
    min-height: 100vh;
  }
  .screen-reader-text { 
    border:0;   
    clip: rect(1px, 1px, 1px, 1px); 
    clip-path: inset(50%);
    height: 1px; 
    margin: -1px;
    width: 1px; 
    overflow: hidden; 
    position: absolute !important;
    word-wrap:normal !important;
 }
 /* Tablet */
/*         */
@media screen and (min-width:501px) and (max-width: 800px){
  .inner-container {
    align-items: center;
    margin-bottom: 60px;
  }
  .slide {
    min-width: 100vw;
    min-height: 0;
    padding: 60px 2.5em 60px 2.5em;
    box-shadow: 0px 0px 20px 1px var(--primary-accent);
  }
}
/* FULL SCREEN */
/*             */
@media screen and (min-width:801px){
  .inner-container {
    width: 800px;
  }
  .slide {
    min-height: 0;
    width: 800px;
    padding: 60px 3em 60px 3em;
    box-shadow: 0px 0px 20px 1px var(--primary-accent);
  }
}
/* Main Styles */
.inner-container:nth-of-type(odd) .slide, .slide{
  background-image: linear-gradient(to top right, var(--secondary-bg-color),var(--main-bg-color), var(--third-bg-color) );
}
.inner-container:nth-of-type(even) .slide, #gallery{
  background-image: linear-gradient(to bottom right, var(--secondary-bg-color),var(--main-bg-color), var(--third-bg-color)  );
}
.main-nav, footer {
  background-image: linear-gradient(to right, var(--secondary-bg-color),var(--main-bg-color), var(--third-bg-color) );
  background-color:var(--main-bg-color);
} 
body {
  max-height: 100vh;
  font: normal 100 1.2rem/1.5rem 'Montserrat', sans-serif;
  background-attachment: static; 
  padding-bottom: 30px;
  overflow-x:hidden;
  background-image: url(./../bg.jpg);
  background-attachment: fixed;
  background-position: center;
}
section {
  overflow: hidden;
}
body.modal-open {
  overflow: hidden;
}
.accent {
  color: var(--primary-accent);
  text-shadow: 1px 1px 10px var(--primary-accent-shadow);
}
main h1, main h2 {
  font-family: 'Roboto', sans-serif;
  line-height: 1em;
  font-weight: 600;
  margin-bottom: .25em;
}
p {
  color: var(--primary-font-color);
  font-weight: 300;
}
section p {
  margin-top: 15px;
}
p a, main ul li a {
  color: var(--secondary-accent);
  text-decoration: none;
}
button {
  background-color: var(--primary-accent);
  color: var(--primary-font-color);
  word-spacing: 0.25em;
  font-size: .7em;
  text-transform: uppercase;
  font-weight: 400;
  border: none;
  margin: 10px 0px;
  line-height: 22px;
  padding: 12px 14px;
  text-align: center;
  box-shadow: 1px 1px 10px var(--primary-accent-shadow);
  border-radius: 5px;
  transition: all .6s;
}
button:hover {
  transform: scale(1.1);
  color: var(--primary-font-color);
}
  