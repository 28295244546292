#emailCaptureForm input{
    font-size: .9em;
    padding:2px 0px;
    margin: 0px 20px 5px 0px;
    vertical-align: middle;

}
#emailCaptureForm button{
padding: 5px 10px;
vertical-align: middle;
}
.error-field {
    height: .7em;
    font-size: .7em;
    color: red;
}
