/* web css */
.inner-container{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    position: relative;
    min-height: 100vh;
}
#web .slide {
  padding-top: 80px;
}
#web #slide1 {
  padding-top: 60px;
}
.hashLink {
    text-decoration: none;
    display: block;
    width: 100%;
    display: inline-block;
    color: var(--primary-font-color);
    font-size: 1em;
    font-variant-caps: all-small-caps;
    opacity:.7;
    margin-top: 10px;
}
 .scrollArrow{
   margin-left: -5px;
    padding: 0 1em;
    background-image:url('../images/downArrow.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: invert(100%);
}
#web #slide6 .scrollArrow, #photo #gallery .scrollArrow{
  background-image:url('../images/upArrow.png');
}
#web .slide ul {
  list-style: none;
  margin-left: .5em;
}
#web ul li::before {
  content: "\25a0";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--primary-accent);
  font-weight: bold;
  font-size: 1.5em; 
  display: inline-block; /*space between the bullet and text */
  width: .5em; /* adjust space between bullet and text */
}
#web .slide ul li{
  font-weight: 400;
  font-size: 1.1rem;
  margin: .25em 0;
  color: var(--primary-font-color);
  font-variant-caps: all-small-caps;
}
#web .slide ul li ul{
  margin: .25em .75em;
}
#web .slide ul li ul li::before{
  color: var(--primary-accent-shadow);
}
/* Individual Modals */
#emailCapture {
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    max-width:360px;
    max-height: 200px;
}
#viewEditEmailList {
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    justify-content: flex-start;
    padding: 60px 10px 60px 30px;
}
#viewEditEmailList h1  {
    width:95%;
    margin: 0 0px 8px 0px;
}
#contactFormExample{
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}
.bucket-container{
    margin-top:1em;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}
#web .bucket {
    width:200px;
    height:250px;
    flex-grow: 1;
    border: 1px solid var(--primary-accent);
}
@media screen and (max-width: 800px){
    .modal-title {
        font-size: 1em;
    }
    .modal-body p {
        font-size: .9em;
    }
    #web .slide ul, #web .slide ul li ul {
      list-style: none;
      margin-left: 0em;
    }
    #web ul li::before {
      content: "";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: var(--primary-accent);
      font-weight: initial; 
      display: none; /*space between the bullet and text */
      width: 0em; 
    }
    #web .slide ul li{
      /* font-size: 1.rem; */
      margin: .25em 0;
      color: var(--primary-font-color);
      font-variant-caps: all-small-caps;
    }
}
@media screen and (min-width: 800px){
  .inner-container:nth-of-type(even) .slide {
    margin: 100vh 0;
  }
}
