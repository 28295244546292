#contactForm h1{
    font-size: 1.5em;
}

#contactForm p{
    font-size: .8em;
}

#contactInfo input{
    font-size: .8em;
    padding:2px 0px;
    margin: 0px 20px 10px 5px;
    vertical-align: middle;
    width: 80%;
}
#contactInfo textarea{
    font-size: 1em;
    padding:2px 0px;
    margin: 0px 20px 10px 5px;
    vertical-align: middle;
    width: 80%;
    height: 80px;
}
#contactInfo button{
padding: 5px 10px;
vertical-align: middle;
}
.error-field {
    margin: 0 0 10px 0;
    height: .8em;
    font-size: .8em;
    font-weight: 800;
    font-variant-caps: all-small-caps;
    color: var(--warning);
}
@media screen and (max-width:489px){
    #contactInfo input{
        padding:2px 0px;
        margin: 0px 0px 10px 0px;
        vertical-align: middle;
        width: 100%;
    }
    #contactInfo textarea{
        padding:2px 0px;
        margin: 0px 0px 10px 0px;
        vertical-align: middle;
        width: 100%;
        height: 80px;
    }
}