  /* Nav Styles Navbar.css*/
  .main-nav{
    position: fixed;
    text-align: center;
    width: 100vw;
    z-index: 300;
    border-bottom: 1px solid var(--secondary-accent);
    height: 40px;
    padding: 10px;
  }
  nav ul{
    list-style: none;
  }
 nav ul li{
    display: inline;
    padding: 0 .8em 0 0;
  }
  nav ul li a {
    color: var(--primary-font-color);
    text-decoration: none;
    font-size: 1em;
    opacity: 1;
  }
  .active {
    border-bottom: 1px solid var(--primary-accent);
  }

  @media screen and (max-width: 800px){
    nav ul li a{
      font-weight: 400;
    }
  }