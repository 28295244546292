/* Main CSS Color Variables for pauldavidrandall.com */
/* ************************************************* */
/* Main Colors Outrun-Mode */
:root {
  --main-bg-color: rgba(0, 170, 255, .9);
  --main-bg-color-solid: rgb(0, 170, 255);
  --secondary-bg-color: #aa00ffe6;
  --third-bg-color: rgba(255, 0, 170, .8);
  --primary-accent: #FF00AA;
  --primary-accent-shadow: rgb(172, 0, 114);
  --secondary-accent: #90ecff;
  --primary-font-color: #fff;
  --inverse-font-color: #000;
  --accent-font-color: #ffcc00;
  --warning: #d50000;
  --caution: #fdd835;
  --success: #64dd17;
}
/* Main Colors Intense */
/* :root {
  --main-bg-color: #6600ff;
  --secondary-bg-color: #4700b3;
  --third-bg-color: #8533ff;
  --primary-accent: #99ff00;
  --secondary-accent: #0099ff;
  --primary-font-color: #fff;
  --inverse-font-color: #0B1C29;
  --accent-font-color: #ff6600;
} */
/* Main Colors Dark-Mode */
/* :root {
    --main-bg-color: #263238;
    --secondary-bg-color: #000a12;
    --third-bg-color: #4f5b62;
    --primary-accent: #29b6f6;
    --secondary-accent: #29b6f6;
    --primary-font-color: #f9f9f9;
    --inverse-font-color: #000;
    --accent-font-color: #73e8ff;
    --warning: #d50000;
    --caution: #fdd835;
    --success: #64dd17;
  } */
  /* Main Colors Light-Mode */
  /* :root {
    --main-bg-color: #f5f5f5;
    --secondary-bg-color: #c2c2c2;
    --third-bg-color: #ffffff;
    --primary-accent: #29b6f6;
    --secondary-accent: #0086c3;
    --primary-font-color: #546e7a;
    --inverse-font-color: #f5f5f5;
    --accent-font-color: #73e8ff;
  } */